@tailwind base;
@tailwind components;
@tailwind utilities;

.animate {
  @apply transition-all duration-200;
}

.center-items {
  @apply flex items-center justify-center; 
}


card{
  @apply flex flex-col rounded-lg shadow bg-white p-5 dark:bg-gray-600;
}

cardheader{
  @apply font-bold text-header border-b-[1px] border-mainColor w-full;
}

cardbody{
  @apply w-full pt-3 
}

cardfooter{
  @apply text-small;
} 

.input-field{
  @apply px-2 py-1 border-[1px] border-vipGreen rounded focus:ring-0 focus:outline-vipGreen placeholder:text-sm placeholder:text-gray-300
}

.button-cancel{
  @apply w-fit h-fit animate text-small font-medium bg-white hover:bg-vipGreen text-black hover:text-white px-10 py-1 rounded-md border-2 border-vipGreen cursor-pointer hover:shadow-xl
}

.button-confirm{
  @apply w-fit h-fit animate text-small font-medium hover:bg-white bg-vipGreen hover:text-black text-white px-10 py-1 rounded-md border-2 border-vipGreen cursor-pointer hover:shadow-xl
}

.button-reverse{
  @apply w-fit h-fit animate text-small font-medium bg-white hover:bg-vipGreen text-black hover:text-white px-10 py-1 rounded-md border-2 border-white cursor-pointer hover:shadow-xl
}

.verifier-title{
  @apply font-light border-b-[1px] w-fit pr-6 mb-2 
}

.radio-button {
  @apply appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-green-600  focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer
}

.yellow-button {
  @apply w-fit h-fit px-6 py-1 font-semibold shadow hover:shadow-lg active:shadow animate cursor-pointer bg-vipYellow rounded
}

.sticky-sidebar {
  position: -webkit-sticky;
  position: sticky;
  top: 85px;
} 

.text-shadow {
  text-shadow:
   -1px -1px 0 #000,  
    1px -1px 0 #000,
   -1px  1px 0 #000,
    1px  1px 0 #000;
}

/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */
